// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("jquery-ui");
// require("@ckeditor/ckeditor5-build-inline");
// require('ckeditor');
import 'bootstrap';

require("../js/ckeditor");
require("../js/script");

import '../stylesheets/application';

global.$ = require("jquery");
// globalThis.$ = jQuery;
// globalThis.jQuery = jQuery;

// $(() => {
//   $( ".section-new" ).on( "click", function(e) {
//     // e.preventDefault();
//     console.log("***" + e.type);
//     // $( "#modalcode" ).html("Контент");
//     // $( "#modalcode" ).dialog( "option", "title", 'Добавить пункт меню' );
//     // $( "#modalcode" ).dialog( "open" );
//   });
// });

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// -----------------------------------------------------
// $(function(){
//   console.log ('*** Скрипт');
//   // Plain jquery
//   $('#fadeMe').fadeOut(5000);

//   // jquery-ui
//   const availableCities = ['Baltimore', 'New York'];
//   $('#cityField').autocomplete( { source: availableCities } );
//   $('#calendarField').datepicker( { dateFormat: 'yy-mm-dd' } );
// })

// import Main from './script.js';
// $(() => Main() );
