// import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';

function Editor() {

  let editorTitle;
  let editorHeadnote;
  let editorBody;
  let token = $('head').find('meta[name="csrf-token"]').attr('content');

  if ( $('#editor-title').length != 0 ) {
    InlineEditor
      .create( document.querySelector( '#editor-title' ), {
        toolbar: {
          items: [
            'heading', '|',
            'fontfamily', 'fontsize', '|',
            'alignment', '|',
            'fontColor', 'fontBackgroundColor', '|',
            'bold', 'italic', 'strikethrough', 'underline', 'removeFormat', '|',
            'highlight', 'removeHighlight', '|',
            'link', '|',
            'outdent', 'indent', '|',
            'specialCharacters', '|',
            'undo', 'redo'
          ],
          viewportTopOffset: 57,
          shouldNotGroupWhenFull: true
        },
        image: {
          toolbar: [
              'imageStyle:full',
              'imageStyle:side',
              '|',
              'imageTextAlternative'
          ]
        },
        language: 'ru'
      })
      .then(editor => {
        editorTitle = editor;

        $('#page-form').on('submit', function (e) {
          $('.page-title').val(editorTitle.getData());
        });

        if (editorTitle.getData() == '') {

          $('#editor-title').on('click', function (e) {
            $('#editor-title').off('click');
            editorTitle.setData('');
            $('#editor-title').removeClass('editor-empty');
          });

          $('#editor-title').addClass('editor-empty');
          editorTitle.setData('Заголовок страницы')
        }

      })
      .catch( error => {
          console.error( error );
      });
  };

  if ( $('#editor-headnote').length != 0 ) {
    InlineEditor
      .create( document.querySelector( '#editor-headnote' ), {
        toolbar: {
          items: [
            'heading', '|',
            'fontfamily', 'fontsize', '|',
            'alignment', '|',
            'fontColor', 'fontBackgroundColor', '|',
            'bold', 'italic', 'strikethrough', 'underline', 'removeFormat', '|',
            'selectAll', 'findAndReplace',  'highlight', 'removeHighlight', '|',
            'link', '|',
            'outdent', 'indent', '|',
            'insertTable', '|',
            'insertImage', '|',
            'mediaEmbed',  'specialCharacters', '|',
            'blockQuote', 'horizontalLine', 'pageBreak', '|',
            'htmlEmbed', '|',
            'undo', 'redo'
          ],
          viewportTopOffset: 57,
          shouldNotGroupWhenFull: true
        },
        image: {
          toolbar: [
              'imageStyle:full',
              'imageStyle:side',
              '|',
              'imageTextAlternative'
          ]
        },
        language: 'ru'
      })
      .then(editor => {
        editorHeadnote = editor;

        $('#page-form').on('submit', function (e) {
          console.log('*** editorHeadnote.getData: ', editorHeadnote.getData());
          $('.page-headnote').val(editorHeadnote.getData());
        });

        if (editorHeadnote.getData() == '') {

          $('#editor-headnote').on('click', function (e) {
            $('#editor-headnote').off('click');
            editorHeadnote.setData('');
            $('#editor-headnote').removeClass('editor-empty');
          });

          $('#editor-headnote').addClass('editor-empty');
          editorHeadnote.setData('Подзаголовок страницы')
        }

      })
      .catch( error => {
          console.error( error );
      });
  };

  if ( $('#editor-body').length != 0 ) {
    InlineEditor
      .create( document.querySelector( '#editor-body' ), {
        ckfinder: {
          uploadUrl: '/ivanovo/admin/uploads',
          headers: {
            'X-CSRF-TOKEN': token,
            'Accept': 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
          },
          options: {
            resourceType: 'Images'
          }
        },
        toolbar: {
          items: [
            'heading', '|',
            'fontfamily', 'fontsize', '|',
            'alignment', '|',
            'fontColor', 'fontBackgroundColor', '|',
            'bold', 'italic', 'strikethrough', 'underline', 'removeFormat', '|',
            'selectAll', 'findAndReplace',  'highlight', 'removeHighlight', '|',
            'link', '|',
            'outdent', 'indent', '|',
            'insertTable', '|',
            'insertImage', '|',
            'mediaEmbed',  'specialCharacters', '|',
            'blockQuote', 'horizontalLine', 'pageBreak', '|',
            'htmlEmbed', '|',
            'undo', 'redo'
          ],
          viewportTopOffset: 57,
          shouldNotGroupWhenFull: true
        },
        image: {
          toolbar: [
            'imageStyle:inline',
            'imageStyle:wrapText',
            'imageStyle:breakText',
            '|',
            'imageTextAlternative'
          ]
        },
        language: 'ru'
      })
      .then(editor => {
        editorBody = editor;

        // console.log('###--- ',  Array.from( editorBody.ui.componentFactory.names() ));

        $('#page-form').on('submit', function (e) {
          $('.page-body').val(editorBody.getData());
        });

        if (editorBody.getData() == '') {

          $('#editor-body').on('click', function (e) {
            $('#editor-body').off('click');
            editorBody.setData('');
            $('#editor-body').removeClass('editor-empty');
          });

          $('#editor-body').addClass('editor-empty');
          editorBody.setData('Тело страницы')
        }

      })
      .catch( error => {
          console.error( error );
      });
  };

};

export default Editor;