function ModalSet(contr_action = null, id = null) {
  let token = $('head').find('meta[name="csrf-token"]').attr('content');
  $("#modalcode").dialog({
    closeOnEscape: true,
    width: 500,
    show: {effect: "blind", duration: 1000},
    hide: {effect: "explode", duration: 1000},
    dialogClass: "d-button",
    buttons: [
      {
        text: "Сохранить",
        style: "background: #5b5; color: #fff; border: 0px; border-radius: 5px;",
        id: "send-form",
        click: function() { 
          console.log("*** Сохранить");

          if (contr_action == 'new') {
            var modalData = '_method=create&';
            var url = '/ivanovo/admin/menus';
          } else {
            var modalData = '_method=patch&';
            var url = '/ivanovo/admin/menus/' + id;
          }

          let hide = $('body').find('#check-hidden')
          if (hide.is(':checked')) {
            var hideValue = true;
          } else { 
            var hideValue = false;
          }

          let inputs = $('.section-input')
          $.each( inputs, function(i) {
            modalData += inputs.eq(i).attr('name') + '=' + inputs.eq(i).val() + '&';
          });
          modalData += 'ivanovo_topic[hidden]=' + hideValue + '&';

          $.ajax({
            url: url,
            type: 'POST',
            headers: {
              'X-CSRF-TOKEN': token
            },
            data: modalData,
            cache: false,
            dataType: 'script'
          })
            .success( function( respond, textStatus, jqXHR ) { $("#modalcode").dialog( "close" ); })
            .error( function( jqXHR, textStatus, errorThrown ) { console.log(textStatus, errorThrown) });
        }

      },
      {
        text: "Отменить",
        style: "background: #e93; color: #fff; border: 0px; border-radius: 5px;",
        id: "cansel-form",
        click: function() {
          $("#modalcode").dialog( "close" );
        }
      }

    ],
    open: function(event, ui) {
      $('.ui-widget-overlay').remove();
      $('.ui-dialog-titlebar-close').hide();
      $('body').append('<div class="ui-widget-overlay ui-front" style="z-index: 90; display: none;"></div>');
      $('.ui-widget-overlay').fadeIn(500);
      $('#send-form').prepend('<span class="icon fa fa-check-square" style="padding-right: 7px;"></span>');
      $('#cansel-form').prepend('<span class="icon fa fa-window-close" style="padding-right: 7px;"></span>');
    },
    close: function(event, ui) {
      $('.ui-widget-overlay').fadeOut(500);
      $('.popover').fadeIn(500);
    }
  });
};

export default ModalSet;