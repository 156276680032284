import ModalSet from './ModalSet.js';
import Editor from './Editor.js';

document.addEventListener("turbolinks:load", function() {

  /***   Переменные   ***/
  let token = $('head').find('meta[name="csrf-token"]').attr('content')
  let clicked;

  /***   Инициализация плагинов Bootstrap   ***/
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover()

  /***   Инициализация CKEditor   ***/
  Editor();

  /***   Обновление порядка страниц   ***/
  let sendChangePage = function(topicId) {
    let pageSpans = $('ul.droptrue[data-id=' + topicId + ']').find('span.page');
    let pages = {};
    // console.log("### pageSpans", pageSpans);
    $.each( pageSpans, function(i) {
      pages[pageSpans.eq(i).data('id')] = [topicId, i + 1];
    });
    $.ajax({
      url: '/ivanovo/admin/pages/change_order',
      type: 'POST',
      headers: {
        'X-CSRF-TOKEN': token
      },
      data: {pages: pages},
      cache: false,
      dataType: 'script'
    })
      .success( function( respond, textStatus, jqXHR ) { console.log(respond); $( "ul.droptrue" ).off('mouseleave'); })
      .error( function( jqXHR, textStatus, errorThrown ) { console.log(textStatus, errorThrown) });
  };

  /***   Обновление порядка топиков   ***/
  let sendChangeTopic = function(sectionId) {
    let headers = $('.portlet-header[data-section=' + sectionId + ']');
    let topics = {};
    console.log("### headers", headers);
    $.each( headers, function(i) {
      topics[headers.eq(i).data('id')] = [headers.eq(i).parent().parent().data('section'), i + 1];
    });
    $.ajax({
      url: '/ivanovo/admin/menus/change_order',
      type: 'POST',
      headers: {
        'X-CSRF-TOKEN': token
      },
      data: {topics: topics},
      cache: false,
      dataType: 'script'
    })
      .success( function( respond, textStatus, jqXHR ) { console.log(respond); $( ".portlet-header" ).off('mouseleave'); })
      .error( function( jqXHR, textStatus, errorThrown ) { console.log(textStatus, errorThrown) });
  };

  /***   Инициализация и отслеживание порядка страниц   ***/
  $( "ul.droptrue" ).sortable({
    connectWith: "ul",
    change: function( event, ui ) {
      // console.log("*** This", $(this));
      // console.log("*** Item", ui.item.data('id'));
      $( "ul.droptrue" ).on('mouseleave', function(e) {
        e.preventDefault();
        sendChangePage($(this).data('id'));
      });
    }
  });

  $( ".droptrue" ).disableSelection();

  /***   Инициализация и отслеживание порядка топиков   ***/
  $( ".column" ).sortable({
    connectWith: ".column",
    handle: ".portlet-header",
    cancel: ".portlet-toggle",
    placeholder: "portlet-placeholder ui-corner-all",
    change: function( event, ui ) {
      let sectionId = $(this).data('section')
      console.log("*** This", $(this));
      $( ".portlet-header" ).on('mouseleave', function(e) {
        e.preventDefault();
        sendChangeTopic(sectionId);
      });
    }
  });

  /***   Модальное окно для добавление топика в секцию   ***/
  $('body').on ('click', '.section-new', function(e) {
    e.preventDefault();
    $('.popover').fadeOut();
    let id = $(this).data('id');
    $.ajax({
      url: '/ivanovo/admin/menus/new/' + id,
      type: 'GET',
      cache: false,
      dataType: 'html'
    }).done( function(data) {
      ModalSet('new');
      $( "#modalcode" ).html(data);
      $( "#modalcode" ).dialog( "option", "title", 'Добавить пункт меню' );
      $( "#modalcode" ).dialog( "open" );
    });

  });

  /***   Модальное окно для редактирования топика   ***/
  $('body').on ('click', '.topic-edit', function(e) {
    e.preventDefault();
    $('.popover').fadeOut();
    let id = $(this).data('id');
    $.ajax({
      url: '/ivanovo/admin/menus/' + id + '/edit',
      type: 'GET',
      cache: false,
      dataType: 'html'
    }).done( function(data) {
      ModalSet('edit', id);
      $( "#modalcode" ).html(data);
      $( "#modalcode" ).dialog( "option", "title", 'Редактировать пункт меню' );
      $( "#modalcode" ).dialog( "open" );
    });

  });

  /***   Окно SEO-параметров   ***/
  $('#seo-set').on('click', function (e) {
    e.preventDefault();
    console.log('*** Клик');
    let seo = $('#seo-block')
    if (seo.css('display') == 'none') {
      $('#seo-block').show(1000);
    } else {
      $('#seo-block').hide(1000);
    }
  });

  /***   Поля формы для страницы   ***/
  $('.input-block').on('click', function (e) {
    e.preventDefault();
    let inputCover = $(this).find('.input-cover')
    if (inputCover.css('display') != 'none') {
      inputCover.fadeOut(500);
      $(this).find('.input-title').css({'visibility':'visible'}).fadeIn(2000);
      $(this).find('.input-item').focus();
    };
  });

  $('.input-item').on('focus', function (e) {
    e.preventDefault();
    let inputBlock = $(this).parent()
    let inputCover = inputBlock.find('.input-cover')
    if (inputCover.css('display') != 'none') {
      inputCover.fadeOut(500);
      inputBlock.find('.input-title').css({'visibility':'visible'}).fadeIn(2000);
      inputBlock.find('.input-item').focus();
    };
  });


  // $('.input-item').on('change', function (e) {
  //   e.preventDefault();
  //   if ($(this).val().length == 0) {
  //     $(this).find('.input-cover').hide(500);
  //     $(this).find('.input-title').css({'visibility':'visible', 'display':'none'}).show(1000);
  //     $(this).find('.input-item').focus();
  //   }
  // });

});